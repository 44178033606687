<template>
  <v-card>
    <v-card-title>{{ $t('startAcceptingOrders') }}</v-card-title>
  </v-card>
</template>

<script>
export default {
    name: 'OnboardingStartAcceptingOrders'
}
</script>