<template>
  <v-row>
    <v-col cols="4">
      <OnboardingAcceptingOrders />
    </v-col>
    <v-col cols="8">
      <v-row>
        <v-col cols="6">
          <OnboardingCompleteStoreProfile />
        </v-col>
        <v-col cols="6">
          <OnboardingCustomizeEmail />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>

import OnboardingAcceptingOrders from "../components/OnboardingAcceptingOrders"
import OnboardingCompleteStoreProfile from "../components/OnboardingCompleteStoreProfile"
import OnboardingCustomizeEmail from "../components/OnboardingCustomizeEmail"

export default {
    name: 'Onboarding',
    components: {
        OnboardingAcceptingOrders,
        OnboardingCompleteStoreProfile,
        OnboardingCustomizeEmail
    }
}
</script>